export function telToDisplay(tel: string) {
    return tel.replaceAll("_", " ")
}


export function telToHref(tel: string) {
    return `callto:+48${tel.replaceAll("_", "")}`
}


export function emailToHref(email: string) {
    return `mailto:${email}`
}

export function addressToDisplay(address: {
    city: string,
    postal: string,
    street: string,
    nr: string,
}) {
    return `${address.street} ${address.nr}, ${address.postal} ${address.city}`
}